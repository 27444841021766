import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import DebugTree from './app/DebugTree'

const finalTheme = createMuiTheme({

});

const App = () => {
    return (
        <ThemeProvider theme={finalTheme}>
            <BrowserRouter>
                <CssBaseline />
                <DebugTree />
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App
