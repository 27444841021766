import { ExpandMore, ChevronRight } from '@material-ui/icons'
import TreeItem from '@material-ui/lab/TreeItem'
import TreeView from '@material-ui/lab/TreeView'
import { mapObjIndexed } from 'ramda'
import React, { useEffect, useState, useRef } from 'react'

const veryUnsafeToken = '021a288ab5';

function recursiveGetTree(item, parent = ''){
    if(typeof item === 'object'){
        return Object.values(mapObjIndexed((val, key) => {
            const id = `${key}-${parent}`;
            const children = recursiveGetTree(val, id);

            if(Array.isArray(children)) return {
                id: id,
                name: key,
                children: children,
            }

            return {
                id: id,
                name: `${key}: ${children}`,
            };
        }, item));
    }

    if(Array.isArray(item)){
        return item.join(', '); // Make better
    }

    return item;
}

const DebugTree = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
    const intervalRef = useRef();

    function doFetch(){
        fetch(`/server/${veryUnsafeToken}`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setData(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
    }

    useEffect(() => {
        doFetch();

        intervalRef.current = setInterval(doFetch, 5000);

        return () => {
            clearInterval(intervalRef);
        }
    }, []);

    if(error) return (
        <em>Could not fetch, error: {error.message}</em>
    );

    if(!isLoaded && !data) return (
        <em>Loading...</em>
    );

    const finalData = data && data.map(player => ({
        id: player.playerData.name,
        name: player.playerData.name,
        children: recursiveGetTree(player, player.playerData.name),
    }));

    const renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </TreeItem>
    );

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMore />}
            defaultExpanded={['root']}
            defaultExpandIcon={<ChevronRight />}
        >
            {renderTree({
                id: 'root',
                name: 'Debug data',
                children: finalData,
            })}
        </TreeView>
    );
};

export default DebugTree
